<template>
  <section id="ticket">
    <div class="ma-10">
      <div class="display-1  primary--text font-weight-bold">Manage Tickets</div>
      
      <router-view />
    </div>
  </section>
</template>

<script>
export default {

};
</script>